<template>
  <div class="login">
    <div class="box-img" :style="`background-image: url(${labaImage});`"></div>
    <div class="login_content_form">
      <div class="login_content_title">
        <p class="ch">国家技术标准创新基地</p>
        <!-- <p class="en">National Technical Standard Innovation Base Of Civil</p> -->
      </div>
      <div style="padding: 30px 80px 45px 80px;">
        <TableFrom ref="from" :data="userForm">
          <TableInput
            :height="48"
            :marginBottom="25"
            label="用户名"
            :labelWidth="0"
            required
            :icon="iconA"
            placeholder="请输入用户名"
            v-model="userForm.account"
          ></TableInput>
          <TableInput
            :height="48"
            :labelWidth="0"
            :marginBottom="20"
            label="密码"
            required
            type="password"
            :icon="iconB"
            :rules="passwordRules"
            placeholder="请输入密码"
            v-model="userForm.password"
          ></TableInput>
        </TableFrom>
        <div class="btns clearfix" style="padding: 0;">
          <router-link class="btn loging pull-left" style="background-color: #155BDD;" to="/">取消登录</router-link>
          <Buttom class="btn loging pull-right" style="background-color: #155BDD;margin-left: 0;" :loading="loading" @click.native="submitForm">
            <span>登录</span>
          </Buttom>
          <!-- <Buttom class="btn loging" style="background-color: #155BDD;" :loading="loading" @click.native="submitForm">
            <span>登录</span>
          </Buttom> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LoginAdminLogin } from '@/api/login.js'
import TableFrom from '@/components/from/TableFrom'
import TableInput from '@/components/from/TableInput'
import md5 from 'js-md5'
const Base64 = require('js-base64').Base64

export default {
  name: 'Login',
  components: {
    TableFrom,
    TableInput
  },
  data () {
    return {
      labaImage: require('../../assets/images/login.png'),
      iconA: require('../../assets/images/login_z.png'),
      iconB: require('../../assets/images/login_p.png'),
      loading: false,
      userForm: {
        account: '',
        password: ''
      },
      passwordRules: [
        // { type: 'regular', verify: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{8,16}$/, msg: '密码必须包含大小写字母，数字，特殊字符，且长度为8-16' }
      ]
    }
  },
  mounted () {
    const that = this
    document.onkeydown = function (event) {
      if (event.which === 13) {
        that.submitForm()
      }
    }
  },
  methods: {
    submitForm () {
      this.loading = true
      this.$refs.from.fromVerify().then(resp => {
        if (resp.code !== '000') {
          this.loading = false
          return
        }
        const token = 'Basic ' + Base64.encode(this.userForm.account + ':' + md5(this.userForm.password))
        window.localStorage.setItem('token', token)
        LoginAdminLogin({}).then(res => {
          this.loading = false
          if (res.code === '000') {
            this.$msg('登录成功', 'success')
            window.localStorage.setItem('token', res.result.tokenType + ' ' + res.result.accessToken)
            this.getInfo()
            // window.localStorage.setItem('loginName', res.result.user.loginName)
            // this.$router.push({ path: '/' })
          }
        })
      })
    },
    getInfo () {}
  }
}
</script>
<style lang="less" scoped>
.login{
  position: fixed;
  padding-top: 120px;
  padding-bottom: 120px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #165CFF;
  background: linear-gradient(135deg, #165CFF 0%, #6795FF 100%);
  .box-img{
    height: 167px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-size: auto 101%;
    background-position: center;
    background-repeat:no-repeat;
  }
  .login_content_form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    background: rgba(104, 150, 255, 0.76);
    width: 500px;
    padding-top: 50px;
    border-radius: 4px;
    .login_content_title {
      text-align: center;
      color: #fff;
      .ch {
        font-size: 32px;
      }
      .en {
        font-size: 16px;
      }
    }
  }
}
</style>
